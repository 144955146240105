import classNames from 'classnames';
import Infobox from '../../../../../common/components/infobox/Infobox';
import styles from './CheckboxLine.module.css';

export const CheckboxIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="var(--color-brand-1000)"
    viewBox="0 0 24 24"
    width="20px"
    height="24px"
  >
    <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z" />
  </svg>
);

const Checkbox = ({
  isSelected,
  isRadioButton = false,
}: {
  isSelected: boolean;
  isRadioButton?: boolean;
}) => (
  <div
    style={{
      pointerEvents: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '18px',
      height: '18px',
      border: '2px solid var(--color-primary)',
      backgroundColor: isSelected ? 'var(--color-primary)' : '',
      flex: '0 0 auto',
      borderRadius: isRadioButton ? '100%' : '3px',
    }}
  >
    {isSelected && <CheckboxIcon />}
  </div>
);

interface Props {
  content: React.ReactNode;
  isSelected: boolean;
  onClick: (e?: any) => void;
  tooltip?: string;
  className?: string;
  isRadioButton?: boolean;
}

const CheckboxLine = ({
  content,
  isSelected,
  tooltip,
  className,
  isRadioButton,
  onClick,
}: Props) => {
  return (
    <div className={classNames(styles.container, className)} onClick={onClick}>
      <div className={classNames(styles.innerContainer)}>
        <Checkbox isSelected={isSelected} isRadioButton={isRadioButton} />
        <div className={classNames(styles.content, { [styles.selected]: isSelected })}>
          {content}
        </div>
      </div>

      {tooltip && (
        <div className={styles.infoBox}>
          {/* TODO: Don't include */}
          <Infobox isSelected={false} description={tooltip} title={content.toString()} />
        </div>
      )}
    </div>
  );
};

export default CheckboxLine;
