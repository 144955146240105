import { identifyUser } from 'common/analytics/posthog';
import { getCookies } from 'common/utils/cookies.utils';
import { APIRequest } from '../../../root.types';
import { UserResponse } from './types';

const createUser =
  (request: APIRequest) =>
  async (parameters: {
    name: string;
    email: string;
    phoneNumber: string;
    password: string;
    referralCode: string;
    cookies?: string;
  }) => {
    await identifyUser({
      phoneNumber: parameters.phoneNumber,
      name: parameters.name,
      email: parameters.email,
    });
    const cookies = getCookies();
    parameters.cookies = cookies;
    return request<{ token: string }>({
      method: 'post',
      path: '/auth/register/user',
      body: JSON.stringify(parameters),
    });
  };

const fetchCurrentUser = (request: APIRequest) => async (parameters: { token: string }) => {
  const { token } = parameters;
  return request<UserResponse>({
    method: 'get',
    path: '/users/current',
    headers: {
      Authorization: token,
    },
  });
};

const updateProfileInformation =
  (request: APIRequest) =>
  async ({ ...params }) => {
    const { token } = params;
    return request({
      method: 'put',
      path: '/users/current/general',
      headers: {
        Authorization: token,
      },
      body: JSON.stringify(params),
    });
  };

const updateMembershipStatus =
  (request: APIRequest) =>
  async ({ ...params }) => {
    const { token } = params;
    return request({
      method: 'patch',
      path: '/users/current/membership',
      headers: {
        Authorization: token,
      },
      body: JSON.stringify(params),
    });
  };

const changePassword =
  (request: APIRequest) =>
  async (parameters: { currentPassword: string; newPassword: string; token: string }) => {
    const { currentPassword, newPassword, token } = parameters;
    return request({
      method: 'put',
      path: '/users/current/password',
      headers: {
        Authorization: token,
      },
      body: JSON.stringify({ currentPassword, newPassword }),
    });
  };

const sendUserPasswordResetMail =
  (request: APIRequest) =>
  async ({ ...params }) => {
    const path = `/users/current/password/reset`;
    return request({
      method: 'post',
      path,
      body: JSON.stringify(params),
    });
  };

const resetUserPassword =
  (request: APIRequest) =>
  async ({ ...params }) => {
    const { userId } = params;
    const path = `/users/current/password/reset/${userId}`;
    return request({
      method: 'post',
      path,
      body: JSON.stringify(params),
    });
  };

// TODO: Might not need this type if we fetch both internal and ACO Booking bookings from mongodb
const fetchUserBookings = (request: APIRequest) => async (parameters: { token: string }) => {
  const { token } = parameters;
  return request({
    method: 'get',
    path: '/users/current/bookings',
    headers: {
      Authorization: token,
    },
  });
};

export default {
  createUser,
  fetchCurrentUser,
  updateProfileInformation,
  updateMembershipStatus,
  changePassword,
  sendUserPasswordResetMail,
  resetUserPassword,
  fetchUserBookings,
};
