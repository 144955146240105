import React from 'react';
import { H3 } from '../../../common/components/texts';
import { appConfig } from '../../../config';
import styles from './FetchErrorMessage.module.css';

const FetchErrorMessage = () => (
  <H3 className={styles.serviceFetchError}>
    Ooopsi! Bookingløsningen vår er på spa for å få seg litt behandling 💅 Ta kontakt med oss{' '}
    <a style={{ color: '#3d0d11' }} href={`${appConfig.typeform.url}`}>
      her
    </a>{' '}
    for å booke time.
  </H3>
);

export default FetchErrorMessage;
