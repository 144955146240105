import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import SectionSubtitle from '../../../common/components/section/SectionSubtitle';
import { setActiveTabMenuContent } from '../forces/actions';
import { getActiveTabMenuContent, getCurrentUser } from '../forces/selectors';
import { Role } from '../forces/types';
import { TabMenuContent } from '../UserPage';
import ChangePassword from './ChangePassword';
import Overview from './Overview';
import styles from './TabMenu.module.css';
import UserBookings from './UserBookings';
import UserInfo from './UserInfo';

const TabMenu = () => {
  const user = useSelector(getCurrentUser);
  const isUserMember = user && user.role === Role.Member;
  const activeTabMenuContent = useSelector(getActiveTabMenuContent);
  const dispatch = useDispatch();

  const getActiveTabMenuContentComponent = () => {
    if (activeTabMenuContent === TabMenuContent.Overview) return <Overview />;
    if (
      activeTabMenuContent === TabMenuContent.UserBookings ||
      activeTabMenuContent === TabMenuContent.BookingInfo
    )
      return <UserBookings />;
    if (activeTabMenuContent === TabMenuContent.UserInfo) return <UserInfo />;
    if (activeTabMenuContent === TabMenuContent.ChangePassword) return <ChangePassword />;
    return isUserMember ? <Overview /> : <UserBookings />;
  };

  const renderHeader = () => {
    if (!isUserMember) {
      return (
        <SectionSubtitle
          onClick={() => {
            dispatch(setActiveTabMenuContent(TabMenuContent.UserBookings));
          }}
          className={styles.guestTab}
        >
          Mine bookinger
        </SectionSubtitle>
      );
    }
    return (
      <div className={styles.header}>
        <SectionSubtitle
          onClick={() => {
            dispatch(setActiveTabMenuContent(TabMenuContent.Overview));
          }}
          className={classNames(styles.text, {
            [styles.activeTab]: activeTabMenuContent === TabMenuContent.Overview,
          })}
        >
          oversikt
        </SectionSubtitle>
        <SectionSubtitle
          onClick={() => dispatch(setActiveTabMenuContent(TabMenuContent.UserBookings))}
          className={classNames(styles.text, {
            [styles.activeTab]: activeTabMenuContent === TabMenuContent.UserBookings,
          })}
        >
          bookings
        </SectionSubtitle>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {renderHeader()}
      <div className={styles.tabMenuContentContainer}>{getActiveTabMenuContentComponent()}</div>
    </div>
  );
};

export default TabMenu;
