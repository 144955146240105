import { useDispatch, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import LoadingIndicator from '../../../../../common/components/loadingIndicator/LoadingIndicator';
import { createRebooking } from '../../../forces/actions';
import {
  getBookingError,
  getBookingId,
  getIsLoadingBooking,
  getIsRebookingValid,
  getSelectedRebookingUser,
} from '../../../forces/selectors';
import styles from './CreateUpdateDeleteBookingButtons.module.css';

const CreateRebookingButton = () => {
  const bookingError = useSelector(getBookingError);
  const isLoading = useSelector(getIsLoadingBooking);
  const isRebookingValid = useSelector(getIsRebookingValid);
  const disableBookingButton = !isRebookingValid || bookingError ? true : false;
  const selectedRebookingUser = useSelector(getSelectedRebookingUser);
  const rebookingPrevBookingId =
    useSelector(getBookingId) ?? window.location.pathname.split('/').pop();
  const dispatch = useDispatch();

  if (isLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingIndicator className={styles.loadingContainer} loadingText={'Booker timen'} />
      </div>
    );
  }

  const handleCreateRebooking = () => {
    dispatch(
      createRebooking({
        rebookingUserid: selectedRebookingUser.id,
        isMemberBookedAsGuest: false,
        rebookingPrevBookingId,
      })
    );
    Scroll.animateScroll.scrollToBottom();
  };

  return (
    <div className={styles.bookSectionContainer}>
      <button
        className={styles.bookButton}
        onClick={handleCreateRebooking}
        disabled={disableBookingButton}
      >
        Rebook
      </button>
    </div>
  );
};

export default CreateRebookingButton;
