import BodyText from '../../../../../../common/components/texts/BodyText';
import styles from './SummarySectionNew.module.css';

type IconRowProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
};
const IconRow = ({ icon, children }: IconRowProps) => {
  return (
    <div className={styles.iconRow}>
      {icon}
      <BodyText style={{ color: 'var(--color-white)' }}>{children}</BodyText>
    </div>
  );
};

export default IconRow;
