import { ArrowRight } from 'common/components/icons/Icons';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import styles from './Navbar.module.css';

type Props = {
  showBanner: boolean;
};

// Define targetDate outside the component to avoid recreating it on every render
const targetDate = dayjs('2025-03-01T00:00:00+01:00');

const TimerBanner = ({ showBanner }: Props) => {
  const title = 'Medlemskampanje: -30% på hårfjerning med laser';
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const isNoTimeLeft =
    timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = dayjs();
      const difference = targetDate.diff(now);

      if (difference <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      setTimeLeft({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      });
    };

    calculateTimeLeft(); // Initial calculation
    const interval = setInterval(calculateTimeLeft, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []); // Empty dependency array ensures this runs only once

  if (!showBanner || isNoTimeLeft) return null;

  return (
    <div className={styles.giftCardBanner}>
      <div className={styles.bannerContent}>
        <span className={styles.callToAction}>{title}</span>
        <div className={styles.timerContainer}>
          <div className={styles.timerElement}>
            <span className={styles.timerValue}>{timeLeft.days}</span>
            <span className={styles.timerLabel}>Dager</span>
          </div>
          <div className={styles.timerElement}>
            <span className={styles.timerValue}>{timeLeft.hours}</span>
            <span className={styles.timerLabel}>Timer</span>
          </div>
          <div className={styles.timerElement}>
            <span className={styles.timerValue}>{timeLeft.minutes}</span>
            <span className={styles.timerLabel}>Minutter</span>
          </div>
          <div className={styles.timerElement}>
            <span className={styles.timerValue}>{timeLeft.seconds}</span>
            <span className={styles.timerLabel}>Sekunder</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimerBanner;
