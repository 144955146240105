import { useDispatch, useSelector } from '../../../../../../common/store/hooks';
import CallToActionButton from '../../../../../../common/components/callToAction/CallToActionButton';
import { BodyText } from '../../../../../../common/components/texts';
import { getCurrentUser } from '../../../../../../domain/user/forces/selectors';
import { Role } from '../../../../../../domain/user/forces/types';
import styles from './SummarySectionNew.module.css';
import { useState } from 'react';
import { getBookingId, getSelectedClinic } from '../../../../../../domain/booking/forces/selectors';
import { deleteBooking } from '../../../../../../domain/booking/forces/actions';
import classNames from 'classnames';

const CancelBookingSection = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const user = useSelector(getCurrentUser);
  const selectedClinic = useSelector(getSelectedClinic);
  const bookingId = useSelector(getBookingId);
  const isUserMember = user && user.role === Role.Member;
  const dispatch = useDispatch();

  const handleCancelBooking = () => {
    dispatch(deleteBooking(selectedClinic.id, bookingId));
  };

  if (showConfirmation) {
    return (
      <div className={styles.deleteContainer}>
        <BodyText className={styles.deleteConfirmationText}>Timen vil bli kansellert.</BodyText>
        <div className={styles.deleteButtonsContainer}>
          <CallToActionButton
            size="large"
            styleType="outlined"
            onClick={() => setShowConfirmation(false)}
          >
            Avbryt
          </CallToActionButton>
          <CallToActionButton size="large" styleType="primary" onClick={handleCancelBooking}>
            Kanseller
          </CallToActionButton>
        </div>
      </div>
    );
  }

  return (
    <CallToActionButton
      styleType="outlined"
      className={classNames(styles.bookButton, styles.cancelButton)}
      onClick={() => setShowConfirmation(true)}
    >
      Kanseller bookingen
    </CallToActionButton>
  );
};

export default CancelBookingSection;
