import { Link, navigate, RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CallToActionButton from '../../common/components/callToAction/CallToActionButton';
import FormAlert from '../../common/components/form/FormAlert';
import FormInput from '../../common/components/form/FormInput';
import { Container } from '../../common/components/grid/Grid';
import Layout from '../../common/components/layout/Layout';
import LoadingIndicator from '../../common/components/loadingIndicator/LoadingIndicator';
import { H2 } from '../../common/components/texts';
import { isAppSetupLoading } from '../app/forces/selectors';
import Routes from '../app/Routes';
import { getGuestInfo } from '../booking/forces/selectors';
import { getIsUserLoggedInAsMember } from '../user/forces/selectors';
import { loginError, loginMember } from './forces/actions';
import { getAuthError, getIsLoadingAuth } from './forces/selectors';
import styles from './LoginPage.module.css';

type Props = {
  createBookingAfterLogin?: boolean;
} & RouteComponentProps;

const LoginPage = ({ createBookingAfterLogin = false }: Props) => {
  const dispatch = useDispatch();
  const isUserLoggedInAsMember = useSelector(getIsUserLoggedInAsMember);
  const authError = useSelector(getAuthError);
  const errorMessage = authError ? 'Noe gikk galt. Skriv inn riktig mobilnummer og passord' : null;
  const isLoadingAppSetup = useSelector(isAppSetupLoading);
  const isLoadingAuth = useSelector(getIsLoadingAuth);
  const guestUserInfo = useSelector(getGuestInfo);
  const [loginData, setLoginData] = useState({
    phoneNumber: guestUserInfo.phoneNumber || '',
    password: '',
  });
  const isValid = loginData.phoneNumber.length > 0 && loginData.password.length > 0;

  const loginUser = () => {
    if (!isValid || isLoadingAuth) {
      return dispatch(loginError(new Error('Oops! Vennligst fyll inn begge feltene')));
    }
    dispatch(loginMember(loginData, createBookingAfterLogin));
  };

  useEffect(() => {
    if (createBookingAfterLogin) return;
    if (isUserLoggedInAsMember) navigate(Routes.UserPage());
  }, [isUserLoggedInAsMember]);

  if (isLoadingAppSetup)
    return (
      <Layout>
        <LoadingIndicator />
      </Layout>
    );

  const renderLogin = () => {
    return (
      <Container className={styles.container}>
        {!createBookingAfterLogin && <H2 className={styles.title}>Logg inn</H2>}
        <FormInput
          defaultValue={loginData.phoneNumber}
          styleType="secondary"
          type="tel"
          placeholder="Mobil nr."
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            authError && dispatch(loginError(null));
            setLoginData({ ...loginData, phoneNumber: evt.target.value });
          }}
          onKeyDown={(evt) => evt.key === 'Enter' && isValid && !isLoadingAuth && loginUser()}
        />
        <FormInput
          defaultValue={loginData.password}
          styleType="secondary"
          type="password"
          placeholder="Passord"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            authError && dispatch(loginError(null));
            setLoginData({ ...loginData, password: evt.target.value });
          }}
          onKeyDown={(evt) => evt.key === 'Enter' && isValid && !isLoadingAuth && loginUser()}
        />
        <FormAlert error={errorMessage} />
        <div className={styles.buttonContainer}>
          <div className={styles.link}>
            {!createBookingAfterLogin && (
              <div>
                <Link type="tertiary" to={Routes.Register()} className={styles.memberLink}>
                  Bli medlem!
                </Link>
                <Link type="tertiary" to={Routes.ForgotPassword()} className={styles.memberLink}>
                  Glemt passord?
                </Link>
              </div>
            )}
          </div>
          <CallToActionButton
            disabled={isLoadingAuth || !isValid}
            className={styles.loginButton}
            onClick={loginUser}
            styleType={createBookingAfterLogin ? 'light' : 'primary'}
          >
            {createBookingAfterLogin ? 'Logg inn og bestill' : 'Logg inn'}
          </CallToActionButton>
        </div>
        {isLoadingAuth && <LoadingIndicator />}
      </Container>
    );
  };

  if (createBookingAfterLogin) return renderLogin();
  return <Layout>{renderLogin()}</Layout>;
};
export default LoginPage;
