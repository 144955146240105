import classNames from 'classnames';
import {
  calculateTotalPriceWithDynamicHours,
  roundNumber,
} from 'domain/booking/sections/serviceSection/forces/service.utils';
import { useSelector } from 'react-redux';
import { BodyBolderText, BodyText } from '../../../../../../common/components/texts';
import { Service } from '../../../../../../root.types';
import { getFilteredServices } from '../../../../forces/booking.utils';
import { getSelectedSlot, getTotalPrice } from '../../../../forces/selectors';
import ServicePrice from '../ServicePrice';
import styles from './SummarySectionNew.module.css';

type ServicesPriceProps = {
  totalPrice: number;
};
const ServicesPrice = ({ totalPrice }: ServicesPriceProps) => {
  return (
    <div className={classNames(styles.sumContainer, styles.serviceRow)}>
      <div className={styles.serviceNameContainer}>
        <div className={styles.sumLine}>
          <BodyBolderText>Sum</BodyBolderText> <BodyText>(Betales i salong)</BodyText>
        </div>
      </div>
      <div className={styles.servicePriceDurationContainer}>
        <div>
          <BodyBolderText>{totalPrice} kr</BodyBolderText>
        </div>
      </div>
    </div>
  );
};

type ServicesOverviewProps = {
  services: Service[];
  appliedDiscountPercentage?: number;
};

const getPrice = (service: Service, slotDiscount: number) => {
  const priceWthDynamicHourDiscount = calculateTotalPriceWithDynamicHours([service], slotDiscount);
  return Math.ceil(roundNumber(priceWthDynamicHourDiscount));
};

const getPriceForServices = (
  service: Service,
  childServices: Service[],
  slotDiscount: number,
  isPackageDeal: boolean,
  hasRequiredServices: boolean
) => {
  const childPrice = childServices.reduce((acc, curr) => acc + getPrice(curr, slotDiscount), 0);
  const totalPrice =
    hasRequiredServices || isPackageDeal
      ? childPrice
      : childPrice + getPrice(service, slotDiscount);

  return Math.ceil(roundNumber(totalPrice));
};

const ServicesOverview = ({ services, appliedDiscountPercentage }: ServicesOverviewProps) => {
  if (!services) {
    return null;
  }

  const { filteredServices } = getFilteredServices(services);
  const totalPrice = useSelector(getTotalPrice)(filteredServices, appliedDiscountPercentage);
  const selectedSlot = useSelector(getSelectedSlot);
  const slotDiscount = appliedDiscountPercentage
    ? appliedDiscountPercentage
    : selectedSlot?.discountValue;
  const childServices = services.filter((service) => service.isChild);
  const parentServices = services.filter((service) => !service.isChild);

  return (
    <div className={styles.servicesOverviewContainer}>
      {parentServices.map((service, index) => {
        const pChildServices = childServices.filter(
          (childService) => childService.parentServiceId === service.id
        );
        const hasRequiredServices = pChildServices.some(
          (childService) => childService.isRequiredAdditionalService
        );
        const isPackageDeal = pChildServices.some((childService) => childService.isPackageDeal);

        // Duration
        const childDuration = pChildServices.reduce((acc, curr) => acc + curr.duration, 0);
        const totalDuration =
          hasRequiredServices || isPackageDeal ? childDuration : childDuration + service.duration;

        // Price
        const [discountedPrice, originalPrice] = [
          getPriceForServices(
            service,
            pChildServices,
            slotDiscount,
            isPackageDeal,
            hasRequiredServices
          ),
          getPriceForServices(service, pChildServices, 0, isPackageDeal, hasRequiredServices),
        ];

        return (
          <div key={service.id} className={styles.serviceRow}>
            <ServiceRow
              service={service}
              childServices={pChildServices}
              totalDuration={totalDuration}
              originalPrice={originalPrice}
              discountedPrice={discountedPrice}
            />
            <hr className={styles.horizontalRule} />
          </div>
        );
      })}
      <ServicesPrice totalPrice={totalPrice} />
    </div>
  );
};

type ServiceRowProps = {
  service: Service;
  childServices?: Service[];
  totalDuration: number;
  originalPrice: number;
  discountedPrice: number;
};
const ServiceRow = ({
  service,
  childServices,
  totalDuration,
  originalPrice,
  discountedPrice,
}: ServiceRowProps) => (
  <div className={styles.serviceRowContainer}>
    <div className={styles.serviceNameContainer}>
      <BodyBolderText className={styles.parentServiceNames}>{service.displayTitle}</BodyBolderText>
      {childServices && childServices.map((childService) => <li>{childService.title}</li>)}
    </div>
    <div className={styles.servicePriceDurationContainer}>
      <div>
        <ServicePrice originalPrice={originalPrice} discountedPrice={discountedPrice} />
      </div>
      <div>
        <BodyText>{totalDuration} min</BodyText>
      </div>
    </div>
  </div>
);

export default ServicesOverview;
