import { ServiceCategory } from 'root.types';

const categories = {
  julekampanje: 'f744525b-a93c-45b7-8ee1-7278a5e8ddf1',
  medlemstilbud: '110df4fc-9995-426a-a19f-d13314d46ffc',
  hudpleie: 'bd3fc159-35fa-4071-8988-6f46a0535df7',
  'hudpleie-microneedling': '556230b9-02bb-4c35-b545-650fac69d345',
  'manikyr-pedikyr': '483f9884-d73c-4cec-836d-9ede74e85ddf',
  'manikyr-med-gel': 'f104e254-0a3f-413a-8c79-e64928393250',
  'bryn-vipper': '30ab4282-af01-40ab-ba5a-460c9ad39a4c',
  voks: '3a333a60-31df-4543-ab34-040fb0bfe6f7',
  laser: '114b422c-e123-480e-b147-c09b23577a0c',
  rynkebehandling: '16fc06f4-29ef-4c79-97a2-08d479b993aa',
  fillerbehandlinger: '4f27f183-a38d-450c-a247-5a216a8ed897',
  'profhilo-skinbooster': '58821502-2b02-4103-b962-3e37d93abdd7',
  mesoterapi: '3d4711e9-7f8d-406a-b593-6ec04fd3d4a1',
  'medisinsk-hudpleie': '533d9c1c-d429-4d0c-9003-b57ae1bb24b2',
};

export const medispaServiceCategoryIds = [
  categories.laser, // Laser - Hårfjerning
  categories.rynkebehandling, // Rynkebehandling
  categories.fillerbehandlinger, // Fillerbehandlinger
  categories['profhilo-skinbooster'], // Profhilo & skinbooster -30%
  categories.mesoterapi, // Mesoterapi -30%
];

export const isMedispaServiceCategoryId = (serviceCategoryId: string) =>
  medispaServiceCategoryIds.includes(serviceCategoryId);

export const groupByMedispaServiceCategory = (
  serviceCategories: ServiceCategory[]
): { medispa: ServiceCategory[]; nonMedispa: ServiceCategory[] } => {
  return serviceCategories.reduce(
    (acc, serviceCategory) => {
      if (isMedispaServiceCategoryId(serviceCategory.id)) {
        acc.medispa.push(serviceCategory);
      } else {
        acc.nonMedispa.push(serviceCategory);
      }
      return acc;
    },
    { medispa: [], nonMedispa: [] }
  );
};

export const getCategoryInfoBoxData = (serviceCategory: ServiceCategory) => {
  if (serviceCategory.id === categories.julekampanje) {
    return {
      title: 'Julekampanje',
      description: '15% rabatt ved kjøp av pakke!',
    };
  }
};
